@import '../../variables.scss';

.search-input {
  display: block;
  width: 100%;
  padding: 8px 10px;
  margin: 10px 0;
  font-family: inherit;
  color: $text-color;
  border: solid 1px var(--border-color);
  background: #f6f7fb;
  border-radius: 5px;
  -webkit-appearance: searchfield;
  transition: all 400ms;

  &:focus {
    outline: none;
  }
}
