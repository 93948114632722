.check-wrapper {
  display: block;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 4px;

  > input {
    margin-right: 10px;
  }

  > input,
  > label {
    vertical-align: middle;
  }
}
