.library-folder {
  width: 31%;
  min-width: 200px;
  margin: 0px 16px 16px 0px;
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  background: #ffffff;
  color: #4a4a4a;

  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    width: 28px;
  }

  p {
    margin: 0px;
    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
