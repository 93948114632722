@import '../../variables.scss';

.alert-bar {
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;

  .alert-content {
    width: 100%;
    opacity: 1;
    color: $text-color;

    i {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}

.alert-bar.success {
  background-color: #4cb39248;
}

.alert-bar.failure {
  background-color: #f0505048;
}

.alert-bar.warning {
  background-color: #f4b21b48;
}
