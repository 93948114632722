.library-file-container {
  position: relative;
  width: 31%;
  min-width: 200px;
  margin: 0px 16px 16px 0px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .download-file {
    pointer-events: none;
  }

  .download-file-button {
    pointer-events: all;
    position: absolute;
    top: 0px;
    right: 8px;
    z-index: 1000;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }
}

.library-file {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  width: 100%;

  p {
    margin: 0;
  }

  .preview {
    width: 100%;
    height: 128px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;

    .default-file-icon {
      width: 20px;
      color: #8f9194;
    }
  }

  .description {
    padding: 16px;

    .title {
      display: flex;
      margin-bottom: 8px;

      .text {
        color: #404040;
        max-width: 14ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .extension {
        color: #404040;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      color: #8f9194;

      .size {
        color: var(--primary-color);
      }
    }
  }
}
