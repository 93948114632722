.choice-list {
  display: flex;
  flex-direction: column;
}

.choice-item {
  display: flex;
  padding: 15px;
  align-items: center;

  &,
  > input {
    cursor: pointer;
  }

  > span {
    margin-left: 8px;
  }

}

.mobile-version {
  .choice-item {
    padding: 3px;
  }
}
