.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}


$task-info-space: 7px;

.task-info {
  display: flex;
  align-items: center;
  font-weight: 500;

  > div + div {
    margin-left: $task-info-space;
    padding-left: $task-info-space;
    border-left: #dde0e6 1px solid;
  }
}


.right-icon {
  margin-left: -7px;
  z-index: 0;
}
