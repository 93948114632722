@import '../../../variables.scss';

.message-modal {
  padding: 40px 56px;
  color: $text-color;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    margin-bottom: 32px;
  }

  .title {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    margin: 0px 0px 16px 0px;
  }

  .description {
    width: 100%;
    max-width: 257px;
    text-align: center;
    margin: 0px 0px 40px 0px;
    span {
      font-weight: bold;
    }
  }

  .reasons {
    width: 100%;
    padding-bottom: 16px;

    .reasons-form-group {
      margin-bottom: 16px;
      input {
        margin-right: 8px;
      }
    }
  }

  .pontuation {
    padding-bottom: 32px;

    .points-label {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin: 0px 0px 8px 0px;
    }

    .points {
      display: flex;
      justify-content: center;
      align-items: center;

      .value {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: var(--primary-color);
        padding-top: 4px;
      }
    }

    img {
      margin-right: 8px;
    }
  }

  .actions {
    padding-top: 32px;
    border-top: 1px solid #eef0f5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      padding: 12px 40px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }
  }
}
