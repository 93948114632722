@import '../../variables.scss';

.chat-list {
  > * {
    text-decoration: none;
    color: #4a4a4a;
  }

  .contact {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 4px;
    border-bottom: 1px solid var(--border-color);

    .profile-info {
      h3 {
        font-weight: bold;
        color: #4a4a4a;
      }
    }

    .comment-preview {
      margin: 16px 0px;
      padding: 8px;
      border-radius: 6px;
      background-color: #f6f7fb;
      font-weight: bold;
      color: #4a4a4a;
      height: 38px;
      overflow: hidden;
    }

    .contact-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .subject {
      color: var(--primary-color);
      font-weight: bold;
      margin-top: 8px;

      span {
        color: #8f9194;
      }
    }

    .created-at {
      color: #8f9194;
    }
  }

  .contact:hover {
    background-color: #f6f7fb;
  }

  @media (max-width: 720px) {
    .contact-footer {
      flex-direction: column;
    }

    .subject,
    .created-at {
      width: 100%;
    }

    .created-at {
      text-align: right;
    }
  }
}
