@import '../../variables.scss';

.cookie-modal {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  max-height: 200px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.cookies-banner {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 16px;
  z-index: 1110;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .close-banner {
    position: absolute;
    top: 8px;
    right: 8px;

    background: #ffffff;
    color: #4a4a4a;
    font-weight: bold;
  }

  p {
    max-width: 600px;
  }
}

@media (max-width: 987px) {
  .cookies-banner {
    bottom: 58px;
    padding-top: 36px;

    .close-banner {
      top: 0px;
      right: 4px;
    }
  }
}
