@import '../../variables.scss';

.post-html {
  img {
    height: auto !important;
  }
}

.view-post {
  color: $dark-color;

  .title-date-task {
    margin: 18px 0;

    p {
      margin: 0 !important;
      font-size: 12px !important;
      color: var(--secondary-text-color) !important;
    }
  }
}

.kaltura-movie {
  width: 100%;
  > div {
    width: 100% !important;
  }
}
