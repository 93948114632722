@import '../../variables.scss';

.post-html {
  img {
    height: auto !important;
  }
}

.view-post {
  color: $dark-color;
}
