@import '../../variables.scss';

.forum-post {
  margin-bottom: 16px;

  .card {
    padding: 0px;

    .profile-info,
    .card-header,
    .card-body,
    .card-footer {
      padding: 16px;
    }

    .profile-info {
      margin-bottom: 0;
    }

    .card-footer {
      small {
        i {
          font-style: normal;
          font-size: 14px;
          text-transform: uppercase;
          color: #8f9194;
        }
      }
    }
  }

  > * {
    text-decoration: none !important;
  }

  .card-body {
    background-color: #f6f7fb;
    padding: 8px;
  }

  .post-comment {
    color: var(--primary-color);
    font-weight: bold;
  }
}

.forum-modal {
  .relative {
    .button {
      display: none;
    }
  }

}
