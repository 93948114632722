@import '../../variables.scss';

.nav-option {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  font-weight: var(--normal);
  cursor: pointer;
  color: inherit;
  border: none;
  border-left: transparent 4px solid;
  background: none;
  transition: all 200ms;
  border-radius: 0px;
  color: var(--secondary-text-color);

  .content-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
  }

  i {
    font-size: 32px !important;
    margin-right: 10px;
    color: var(--secondary-text-color);
    font-weight: normal;
  }

  i:hover {
    text-decoration: none;
  }

  path {
    fill: #bbbec4;
  }

  &.exit {
    font-weight: var(--normal);
    color: var(--secondary-nav-color);
  }

  &:hover,
  &.active {
    color: var(--secondary-nav-color);
    border-left-color: var(--secondary-nav-color);

    i {
      color: var(--secondary-nav-color);
    }
  }

  &:hover {
    background: #f6f7fb;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &.exit {
    .nav-icon {
      path {
        fill: var(--secondary-nav-color);
      }
    }
  }
}

.mobile-version {
  .nav-option {
    width: auto;
    border-left: none;
    text-align: center;
    font-size: 12px;
    font-weight: var(--normal);
    color: #bbbec4;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      i {
        margin: 0px;
        font-size: 24px !important;
        line-height: 1;
      }
    }

    &.active, &:hover {
      color: var(--secondary-nav-color);

      path {
        fill: var(--secondary-nav-color);
      }
    }
  }
}

.submenu {
  width: max-content;
  position: absolute;
  top: 0px;
  left: calc(100% - 20px);
  background-color: #ffffff;
  z-index: 1010;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
  display: none;

  div {
    button, a {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px;
      text-align: left;
      text-decoration: none;
      font-weight: var(--normal);
      cursor: pointer;
      color: inherit;
      border: none;
      border-left: transparent 4px solid;
      background: none;
      transition: all 200ms;
      border-radius: 0px;
      color: var(--secondary-text-color);

      i {
        color: var(--secondary-text-color) !important;
      }

      path {
        fill: #bbbec4;
      }

      &.exit {
        font-weight: var(--normal);
        color: var(--secondary-nav-color);
      }

      &:hover,
      &.active {
        color: var(--secondary-nav-color);
        border-left-color: var(--secondary-nav-color);

        i {
          color: var(--secondary-nav-color) !important;
        }
      }

      &:hover {
        background: #f6f7fb;
      }

      &:focus {
        outline: none;
      }

      &:hover,
      &.exit {
        .nav-icon {
          path {
            fill: var(--secondary-nav-color);
          }
        }
      }
    }
  }
}

.nav-option:hover {
  .submenu {
    display: block;

    div {
      color: #4a4a4a;

      path {
        fill: #bbbec4;
      }

      &:hover {
        path {
          fill: var(--secondary-nav-color);
        }
      }
    }
  }
}

.nav-icon,
.nav-img {
  max-width: 32px;
  max-height: 32px;
  margin-right: 10px;
}

.nav-img {
  border-radius: 50%;
}
