.tags {
  margin-bottom: 15px;

  > span {
    font-size: 0.8rem;
    font-weight: 500;
  }

  > a {
    margin: 0 5px;
  }
}
