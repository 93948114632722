@import '../../variables.scss';

.survey-question-card {
  padding: 0px;

  .profile-info {
    padding: 15px;
  }

  .helper-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    background: linear-gradient(to right, var(--secondary-color), var(--tertiary-color));
    padding: 16px;

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .question-status-container, .question, .footer {
    padding: 32px;
    border-bottom: 1px solid #eef0f5;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    border: none;

    button {
      background: none;
      border: none;
      color: var(--primary-color);
      cursor: pointer;
      transition: all .2s;
    }

    button:hover {
      text-decoration: underline;
    }
  }
}
