@import '../../../variables.scss';

.public-wrapper {
  position: relative;
  background: $login-bg;
  padding-bottom: 126px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .cookies-banner {
    position: absolute;
  }


  .mobile-version & {
    padding-bottom: 0;
  }
}
