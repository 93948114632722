.store {
  display: inline-block;
  margin: 0 10px 8px 10px;
  vertical-align: middle;
  transition: transform 200ms;

  &:hover {
    transform: scale(1.05);
  }

  > img {
    height: 40px;
  }
}
