@import '../../variables.scss';

.separator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 20px 0;

  &:before,
  &:after {
    content: '';
    display: block;
    flex: 1;
    width: 100%;
    height: 1px;
    background: $separator-color;
  }

  > span {
    padding: 0 15px;
  }
}
