@import '../../variables.scss';

.bold {
  font-weight: var(--bold);
}

.img32 {
  width: 32px;
  height: 32px;
}

.pa20 {
  padding: 20px;
}

.pa40 {
  padding: 40px;
}

.gray-bg {
  background-color: $gray;
}

.lh1_2 {
  line-height: 1.2em;
}

.mr20 {
  margin-right: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr10 {
  margin-right: 10px;
}

.w150 {
  width: 150px;
}

.account-menu {
  border-bottom: 1px solid $gray;

  a {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-light-color;
    text-decoration: none;
    padding: 16px 12px;
    position: relative;
    display: inline-block;

    &.active {
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
    }

    .mobile-version & {
      padding: 10px 5px;
    }
  }
}

.history-image-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.text-center {
  text-align: center;
}

.p20 {
  padding: 20px;
}

.flex {
  display: flex;
}

.flex-middle {
  align-items: center;
}

.profile-img {
  width: 80px;
  height: 80px;
}

.file-button-hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  opacity: 0;
}

.mobile-version {
  .input-group {
    flex-direction: column;
    margin-bottom: 0;

    > * {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  .mobile-buttons-full {
    .button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &.last-button {
        margin-top: 12px;
      }
    }
  }

  .account-screen {
    // margin-bottom: 40px;
    margin-bottom: 20px;

    .input-addr_cep {
      width: 100%;
    }

    .form-section {
      flex-direction: column;

      div:first-child {
        margin-right: 0px;
        width: auto;
      }
    }

    .section-wrapper {
      padding: 15px;

      .title-flex-wrapper {
        flex-direction: column;
      }

      .you-at-ranking-wrapper {
        margin-top: 20px;
      }
    }
  }
}

.input-group {
  margin-bottom: 20px;
  display: flex;

  > * {
    flex: 1;
    margin: 0 5px;
  }

  // input, select {
  //   margin: 0;
  // }
}

.account-screen {
  // padding: 32px;
  background: white;
  color: $text-color;
  font-size: 14px;

  h2 {
    font-size: 24px;
    font-weight: var(--bold);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }

  p {
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $text-light-color;
    margin-bottom: 0;
  }

  .section-wrapper {
    padding: 32px;
    border-bottom: 1px solid $gray;
  }

  .rounded {
    border-radius: 50%;
  }

  .ml10 {
    margin-left: 10px;
  }

  .phone-wrapper {
    display: flex;
  }

  .input-addr_cep {
    width: 200px;
  }

  .delete-account {
    div {
      width: 100%;
      margin-right: 32px;
      margin-bottom: 32px;

      display: flex;
      justify-content: flex-end;
    }
  }

  .delete-account-form div {
    margin: 32px;
  }

  .form-section {
    display: flex;

    > div:first-child {
      width: 192px;
      margin-right: 20px;
    }

    > div:last-child {
      flex: 1;
    }

    h3 {
      // font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    label {
      display: block;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $text-light-color;
      margin-bottom: 8px;
    }
  }
}

.historic-date-separator {
  @extend .section-wrapper;
  text-transform: uppercase;
  text-align: center;
  color: var(--text-color);
  // padding: 20px;
  // border-bottom: 1px solid $gray;
}

.social-icon {
  @extend .rounded;
  @extend .mr10;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: white;

  &.instagram {
    background: $instagram-color;
  }

  &.whatsapp {
    background: $whatsapp-color;
  }

  &.facebook {
    background: $facebook-color;
  }

  &.twitter {
    background: $twitter-color;
  }

  &.twitter.x {
    background: $x-color;
  }

  &.linkedin {
    background: $linkedin-color;
  }

  &.youtube {
    background: $youtube-color;
  }

  &.check {
    background: var(--success-color);
  }

  &.publishing {
    background: var(--primary-color);
  }

  &.member-ok {
    background: var(--primary-color);
  }
}

.historic-item {
  @extend .section-wrapper;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // padding: 20px 0;
  // border-bottom: 1px solid $gray;

  .light {
    color: $text-light-color;
  }

  .points {
    @extend .light;
  }

  .revoked {
    color: $failure-color;
  }

  .break-line {
    flex-basis: 100%;
    height: 0;
  }

  .revoke-description {
    strong {
      margin-left: 4px;
    }
  }
}

.connection-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: $gray;
}

.no-itens-message {
  font-size: 16px;
  text-align: center;
  padding: 50px 0;
  margin: 0;
}

.invite-connections-center {
  max-width: 600px;
  margin: 0 auto;
}


.invite-connections {
  padding: 20px 20px 40px;

  p {
    margin-bottom: 30px;
  }
}

.box-form {
  background-color: white;
  padding: 16px;

  legend {
    max-width: 188px;
    margin: 0 auto 22px;
    font-size: 14px;
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $text-light-color;
  }

  input {
    margin: 0;
  }
}

.connections-summary {
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .small {
    display: inline-block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $text-color;
  }
}

.degree-connections {
  display: flex;
  justify-content: center;

  > div {
    padding: 0 86px;
    border-right: 1px solid $separator-color;

    &:last-child {
      border-right: none;
    }

    .mobile-version & {
      padding: 0 15px;
    }
  }

  .title {
    font-size: 10px;
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: $text-light-color;
    text-transform: uppercase;
  }

  .count {
    font-size: 24px;
    font-weight: var(--bold);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-color);
  }
}


.historic-item-mobile {
  padding: 10px;
  border-bottom: 1px solid #f6f7fb;
  flex-wrap: wrap;

  .revoke-description {
    strong {
      margin-left: 4px;
    }
  }

  .date-info {
    color: $text-light-color;
  }

  p:not(.revoke-description) {
    color: #4a4a4a;
    margin-top: 11px;
    margin-bottom: 9px;
  }

  .historic-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social-icon, .history-image-icon {
    display: inline-block;
    margin-right: 0;
    margin-left: 10px;
  }

  .star {
    width: 26px;
    margin-right: 5px;
  }

  .points {
    vertical-align: middle;
  }

  .revoked {
    color: $failure-color;
  }

  .break-line {
    flex-basis: 100%;
    height: 0;
  }
}


.organization-image {
  width: 26px;
  margin-right: 5px;
}

.sub-form-button {
  margin-top: 26px;

  .mobile-version & {
    margin-top: 15px;
  }
}
