@import '../../variables.scss';

.modal-overlay {
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(#000, 0.75);
  z-index: $z-index-modal;
}

.modal {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  position: relative;
  overflow: visible;
  border: none;
  background: #fff;
  border-radius: 4px;

  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
    color: $secondary-inverse;
    background: var(--primary-color);
    border-radius: 50%;
    opacity: 1;
    line-height: 30px;
    text-align: center;
    font-size: 28px;
    transition: all .2s;
    padding: 17px;
  }
}
