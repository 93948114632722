@import '../../variables.scss';

.downloads {
  .subtitle {
    margin-bottom: 32px;
  }

  .response-message {
    font-weight: bold;
    color: #8f9194;
  }
}

.download-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px #0002;
  border: 1px solid #eee;

  h3 {
    margin: 0;
    margin-bottom: 16px;
  }

  .description {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
  }

  .org-image {
    width: 64px;
    height: 64px;
    background-color: #ccc;
    border-radius: 50%;
  }

  .download-item-content {
    width: 100%;
    padding: 0 16px;

    display: flex;
    justify-content: space-between;

    .left-side {
      width: 82%;
      .error-message {
        color: var(--failure-color);
      }

      button {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        background: none;
        color: var(--primary-color);
        font-weight: bold;
        margin-top: 16px;

        display: flex;
        justify-content: center;

        > * {
          margin: 0 4px;
        }

        svg {
          margin: 0;
          margin-right: -12px;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        :hover {
          text-decoration: underline;
        }
      }
    }

    .right-side {
      width: 18%;
      text-align: end;
    }

    .date {
      margin: 0px;
      color: #8f9194;
    }
  }

  @media (max-width: 987px) {
    .download-item-content {
      flex-direction: column;

      .left-side,
      .right-side {
        width: 100%;
      }

      .right-side {
        text-align: start;
      }
    }
  }
}
