@import '../../variables.scss';

.link {
  text-decoration: none;
  font-weight: var(--bold);
  color: var(--primary-color);

  &:hover {
    text-decoration: underline;
  }
}
