@import '../../variables.scss';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  background: rgba(#000, 0.6);
  z-index: 9999;
  transition: opacity 400ms;
  opacity: 0;

  > svg {
    background: none;

    > circle {
      fill: var(--primary-color);
    }
  }

  &.visible {
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
}

.loader-inline-wrapper {
  padding: 20px;
}
