@import '../../variables.scss';

.expanded-wrapper {
  width: 100%;
  min-height: 100%;
  position: relative;
  color: var(--background-inverse-color);
  background: var(--background-color);
  padding-bottom: 48px;
  display: flex;
  justify-content: center;

  .expanded-main {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
}
