.creators-form-question {
  width: 100%;
  padding: 16px 0px;
  border-bottom: 1px solid var(--border-color);

  &:last-child {
    border: none;
  }

  .title {
    color: var(--text-color);
    font-weight: bold;
    margin: 0px;
    margin-bottom: 4px;
  }

  .input {
    margin: 0px;
  }

  .error-message {
    color: var(--failure-color);
    margin: 0;
    padding-top: 6px;
  }
}

.creators-form-task-question {
  border: none;

  margin-bottom: 6px;
}
