@import '../../variables.scss';

.contact-us-screen {
  .contact-us-header {
    margin-bottom: 32px;
  }

  .contact-us-content {
    width: 100%;

    .toggle-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 16px 0px;
      padding-top: 0px;

      .toggle-section {
        height: 40px;
        width: 190px;
        box-sizing: border-box;
        background: none;
        border: none;
        outline: none;
        text-transform: uppercase;
        color: #8f9194;
        border-bottom: 1px solid #eee;
        cursor: pointer;
      }

      .toggle-section.active {
        color: var(--primary-color);
        border-bottom: 4px solid var(--primary-color);
      }
    }
  }

  .loading {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin: 32px 0px;
  }
}
