@import '../../variables.scss';

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 10px;
  font-weight: var(--bold);
  vertical-align: middle;
  color: $badge-color;
  background: $badge-background;
  border-radius: 50%;
}
