.trail-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;

  path {
    fill: var(--primary-color);
  }
}

.trail-title {
  font-size: 16px;
  font-weight: bold;
}

.trail-description {
  font-size: 14px;
  color: #8f9194;
}

.trail-values {
  display: flex;
  margin-bottom: 16px;

  .percent,
  .statistics {
    width: 50%;
  }

  .percent {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      width: 46%;
    }

    .percent-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 36px;
        vertical-align: top !important;
        margin: 0px;
        margin-left: 10px;

        span {
          position: relative;
          font-size: 14px;
          vertical-align: top;
          top: 10px;
        }
      }

      p {
        font-size: 10px;
        text-transform: uppercase;
        margin: 0px;
      }

      @media (max-width: 602px) {
        h1 {
          font-size: 28px;
          text-align: center;
          margin: 0;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 602px) {
    .percent {
      > div {
        width: 60%;
        margin: 0px;
      }
    }
  }

  .statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .trail-tasks,
    .trail-points {
      margin-right: 32px;
      width: 100px;

      h3 {
        font-size: 24px;
        line-height: 18px;
        margin: 0px;
        margin-bottom: 4px;

        span {
          color: var(--primary-color);
        }
      }

      p {
        margin: 0px;
        text-transform: uppercase;
        font-size: 10px;
      }
    }

    @media (max-width: 602px) {
      .trail-tasks {
        margin-bottom: 16px;
      }
    }
  }
}
