@import '../../variables.scss';

.social-share {
  display: flex;

  > div {
    flex: 1;

    > h4 {
      margin-top: 0;
    }
  }
}
