@import '../../variables.scss';

.site-edukative {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #5487ff;
  --secondary-color: #1a38a8;
  --secondary-nav-color: #1696e1;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #25e300;
  --failure-color: #ff4b2a;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F7FB;
  background-image: url('../../assets/images/bg-pattern.png');
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #5487ff 0%, #1a38a8 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }

  .logo {
    width: 144px;
  }

  .restricted-header {
    .logo {
      margin-left: 37px;
    }
  }

  .copyright {
    margin: 5px 0;
  }

  .public-footer {
    margin-top: 30px;
  }
}

.site-comex {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #14191F;
  --secondary-color: #0FDB40;
  --secondary-nav-color: #0FDB40;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #4cb392;
  --failure-color: #f05050;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #0FDB40 0%, #0FDB40 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-elle {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #F60000;
  --secondary-color: #F60000;
  --secondary-nav-color: #F60000;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #4cb392;
  --failure-color: #f05050;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #F60000 0%, #F60000 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-flow {
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  background-color: #F6F6FB;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, var(--secondary-color) 0%, var(--tertiary-color) 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-lhh {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #832796;
  --secondary-color: #FF8F3C;
  --secondary-nav-color: #E1376A;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #4cb392;
  --failure-color: #f05050;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #832796 0%, #FF8F3C 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-geracao_s {
  --background-color: #6bb230;
  --background-inverse-color: #ffffff;
  --header-height: 75px;
  --primary-color: #6bb230;
  --primary-inverse-color: #ffffff;
  --secondary-color: #ff8f08;
  --secondary-nav-color: #ff8f08;
  --secondary-inverse-color: #a18888;
  --success-color: #6bb230;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  height: 100%;

  .logo {
    height: 75px;
  }

  .copyright {
    @media (min-width: $break-min) {
      margin-left: 10px;
    }
  }
}

.site-talkative {
  --background-color: #f6f7fb;
  --background-inverse-color: #8f9194;
  --header-height: 64px;
  --primary-color: #00bc9b;
  --secondary-color: #1696e1;
  --secondary-nav-color: #1696e1;
  --primary-inverse-color: #4a4a4a;
  --secondary-inverse-color: #ffffff;
  --success-color: #6bb230;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #00BC9B 0%, #5D59D2 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }

  .logo {
    width: 144px;
  }

  .restricted-header {
    .logo {
      margin-left: 37px;
    }
  }

  .copyright {
    margin: 5px 0;
  }

  .public-footer {
    margin-top: 30px;
  }
}

.site-grupo_fsb {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #161a28;
  --secondary-color: #161a28;
  --secondary-nav-color: #161a28;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #4cb392;
  --failure-color: #f05050;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #F60000 0%, #F60000 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-cidadania {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #1a2e4c;
  --secondary-color: #db028b;
  --secondary-nav-color: #db028b;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #4cb392;
  --failure-color: #f05050;
  --warning-color: #f4b21b;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #1a2e4c 0%, #1a2e4c 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}

.site-bora {
  --background-inverse-color: #8f9194;
  --header-height: 76px;
  --primary-color: #B4CD23;
  --secondary-color: #707070;
  --secondary-nav-color: #B4CD23;
  --primary-inverse-color: #ffffff;
  --secondary-inverse-color: #4a4a4a;
  --success-color: #0AE569;
  --failure-color: #D03A41;
  --warning-color: #F1CC07;
  --success-inverse-color: #ffffff;
  background-color: #F6F6FB;
  // background-repeat: repeat;
  // background-size: cover;
  // background-attachment: fixed;
  min-height: 100%;

  .message-modal-header {
    background-color: transparent;
    position: relative;
    z-index: 0;
    background: none;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      z-index: -2;
      background-image: linear-gradient(135deg, #B4CD23 0%, #B4CD23 100%);
    }

    &:after {
      z-index: -1;
      background: url('../../assets/images/rays.svg') center top no-repeat;
    }
  }
}
