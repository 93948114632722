.input-group  {
  .uf-input-wrapper {
    width: 60px;
    flex: initial;
  }

  .number-input-wrapper {
    width: 95px;
    flex: initial;
  }
}
