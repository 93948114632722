.profile-info {
  display: flex;
  align-items: center;

  .profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 1;
  }

  .nav-icon {
    margin: 0px;
    path {
      fill: #4cb392;
    }
  }
}

.profile-info-name {
  flex: 1;
  margin-left: 15px;
  text-align: left;

  h3 {
    margin-top: 0;
    margin-bottom: -5px;
    font-weight: var(--normal)
  }
}
