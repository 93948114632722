@import '../../../variables.scss';

.check-task-wrapper {
  max-width: 375px;
  margin: auto;

  .button {
    margin-top: 15px;
  }
}

.card-body {
  .content-card {
    & > img {
      width: 100%;
    }
    margin-bottom: 18px;

    .suggested-caption {
      padding-top: 15px;
      white-space: pre-wrap;
    }

    .title-date-task {
      margin: 18px 0;
      p {
        font-size: 12px;
        color: var(--secondary-text-color);
      }
    }
  }

}

.default-task-body:not(.user-content) {
  padding-bottom: 15px;

  .suggested-caption {
    padding-top: 15px;
    white-space: pre-wrap;
  }
}

.check-task-wrapper .text-center input{
 margin-bottom: 10px;
}

video{
  margin-bottom: 10px;
}

%card-social-fix {
  .post-header {
    margin: -15px -15px 0;
    padding: 15px;
    color: white;
  }

  .card-footer {
    margin: 0 -15px -15px;
    padding: 15px;

    .button {
      margin: 0;
    }
  }

  .card-body {
    overflow: hidden;
    margin: 0 -15px;
    padding: 15px;
    background: rgba(0,0,0, 0.1);
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .comment-buttons {
    color: white;

    .icon {
      color: white;
    }

    .secondary {
      color: white;
    }
  }

  .comment-box-title {
    color: white;
  }
}

// .task-twitter {
//   background: #00aced;
//   @extend %card-social-fix;
// }

// .task-facebook {
//   background: #3a589b;
//   @extend %card-social-fix;
// }

// .task-instagram {
//   background-image: radial-gradient(circle at 2% 91%, #fed576, #f47133 26%, #bc3081 61%, #4c63d2);
//   @extend %card-social-fix;
// }

// .task-linkedin {
//   background: #0077b5;
//   @extend %card-social-fix;
// }

// .task-youtube {
//   background: #8c181c;
//   @extend %card-social-fix;
// }

// .task-whatsapp {
//   background: #0a6934;
//   @extend %card-social-fix;
// }
