.faq {
  .card {
    padding: 32px;

    .faq-row-wrapper {
      margin-bottom: 32px;

      svg {
        width: 25px !important;
        height: 25px !important;
      }

      .faq-title {
        h2 {
          color: var(--text-color);
        }
      }

      .row-content-text {
        color: var(--text-color);
      }
    }

    .faq-row {
      .row-title {
        color: var(--primary-color);
      }
    }
  }
}
