@import '../../variables.scss';
.library-screen {
  .page-header {
    .text-info {
      h1 {
        margin-bottom: 0px;
      }
    }
  }

  .library-content {
    .section-title {
      color: #8f9194;
      margin-top: 16px;
    }

    .folders, .files, .links {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .empty-folder {
    text-align: center;
    color: #8f9194;
  }
}
.modal-link {
  color: $dark-color;
}
