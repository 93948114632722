@import '../../variables.scss';

$message-modal-border-radius: 8px;

.pv20 {
  padding-left: 20px;
  padding-right: 20px;
}

.message-modal-overlay {
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(#000, 0.75);
  z-index: $z-index-modal;

  .mobile-version & {
    padding: 0 10px;
  }
}

.message-modal {
  padding: 0px !important;
  max-width: 450px;
  margin: 40px auto;
  position: relative;
  overflow: visible;
  border: none;
  background: #fff;
  color: $dark-color;
  border-radius: $message-modal-border-radius;
}

.message-modal > div {
  width: 100%;
}

.message-modal-header {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: white;
  background: #33c130 url('../../assets/images/rays.svg') center top no-repeat;
  border-radius: $message-modal-border-radius $message-modal-border-radius 0 0;
}

.message-modal-content {
  padding: 20px 40px;
}

.message-modal-ok {
  max-width: 260px;
  margin: 0 auto 25px;
  font-weight: var(--bold);
  font-size: 1.4rem;
  line-height: 0.8;
}


.mobile-version {
  .message-modal-content {
    padding: 8px 20px;
  }

  .message-modal-ok {
    margin-bottom: 17px;
  }

  .message-modal-header {
    padding: 15px;

    h2, h3 {
      margin-bottom: 0;
    }
  }
}
