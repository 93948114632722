@import '../../variables.scss';

.close {
  // display: inline-block;
  padding: 0;
  font-size: 32px;
  vertical-align: middle;
  line-height: 0.8;
  cursor: pointer;
  // color: #f00;
  border: none;
  // background: none;
  // opacity: 0.6;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}
