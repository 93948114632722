.bonus-modal {
  max-width: 450px;
  margin: 40px auto;
  position: relative;
  overflow: visible;
  border: none;
  background: #fff;
  color: #111;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;

  .bonus-title {
    display: block;
    text-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-size: 72px;
    font-weight: var(--bold);
    font-style: normal;
    font-stretch: normal;
    line-height: 0.55em;
    letter-spacing: -4px;
    background-image: url('../../assets/images/sparkles.svg');
    color: #ffffff;
    height: 120px;
    background-position: center;
    padding: 35px;
    margin-bottom: 40px;

    span {
      display: block;
      font-size: 18px;
      color: #edd100;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .header-modal-bonus {
    background-color: #6bb230;
    background-image: url('../../assets/images/rays-2.svg');
    background-position: top center;
    padding: 30px 0 40px;
  }

  .days {
    display: flex;
    justify-content: center;
  }

  .day {
    width: 48px;
    height: 88px;
    background: white;
    margin: 0 4px;
    font-size: 10px;
    font-weight: var(--bold);
    line-height: 1.4;
    letter-spacing: normal;
    color: #b1b1b1;
    position: relative;
    border-radius: 8px;

    &.day-before {
      background-color: rgba(0, 0, 0, 0.2);
      color: white;

      .count {
        color: #edd100;
      }
    }

    &.day-current {
      background-color: var(--primary-color);
      color: white;

      .count {
        color: white;
      }
    }

    .coin {
      background-image: url('../../assets/images/prize-3.svg');
      margin-top: -16px;
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-top: -17px;
    }

    .count {
      font-family: CircularStd;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ff8f08;
      display: block;
    }

    %day-icon {
      width: 16px;
      height: 16px;
      margin-top: 7px;
      display: inline-block;
    }

    .check {
      @extend %day-icon;
      background-image: url('../../assets/images/check.svg');
    }

    .check-green {
      @extend %day-icon;
      background-image: url('../../assets/images/check-green.svg');
    }

    .lock {
      @extend %day-icon;
      background-image: url('../../assets/images/lock.svg');
      width: 17px;
    }

    .day-footer {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      color: white;
      font-size: 16px;
    }
  }

  .content-paragraph-featured {
    font-size: 14px;
    color: #4a4a4a;
    margin-block-start: 1em;
    margin-block-end: 1em;

    span {
      display: block;
      font-size: 24px;
      font-weight: var(--bold);
      color: #ff8f08;
    }
  }

  .normal-paragraph {
    font-size: 14px;
    color: #bbbec4;
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  #modal-bonus-footer {
    margin-top: 12px;

    .button {
      margin: 10px 0;
    }
  }
}
