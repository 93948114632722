@import '../../variables.scss';

.copyright {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;

  @media (min-width: $break-min) {
    margin-left: 10px;
  }

  @media (max-width: $break-max) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
