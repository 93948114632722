@import '../../variables.scss';

.download-screen {
  width: 100%;
  margin: 192px 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  .link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: none;
    color: var(--success-color);
    font-weight: bold;
    margin-top: 16px;
  }

  .text-content {
    text-align: center;
    padding: 32px;
  }
}
