@import '../../variables.scss';

.content {
  padding-bottom: 48px;
}

.content .container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-menu-aside {
  position: fixed;
  z-index: 1000;
  top: --var(--header-height);
  float: left;
  // overflow: hidden;
  height: max-content;
  border-radius: 0 0 4px 4px;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-height: 840px) {
  .nav-menu-aside {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 520px;

    .submenu {
      width: 220px;
      left: 0px;
      top: 52px;
    }
  }
}

//   .nav-menu-aside::-webkit-scrollbar-track {
//     background-color: var(--primary-color);
//   }
//   .nav-menu-aside::-webkit-scrollbar {
//     width: 6px;
//     background: var(--primary-color);
//   }
//   .nav-menu-aside::-webkit-scrollbar-thumb {
//     background: #00000030;
//   }
// }

.nav-menu-aside .stores-links {
  width: 220px;
}

.restricted-wrapper {
  width: 100%;
  min-height: 100%;
  position: relative;
  color: var(--background-inverse-color);
  background: var(--background-color);
}

.restricted-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: $restrict-header-color;
  background: $restrict-header-background;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: $z-index-header;

  .container {
    display: flex;
    justify-content: space-between;
  }
}

.logo-wrapper {
  padding: 8px;
  width: 220px;
  max-width: 220px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: var(--header-height);

  a {
    width: 112px;

    .logo {
      width: 100%;
    }
  }
}

.content {
  padding-top: var(--header-height);
  min-height: 100vh;

  > .container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    min-height: 100%;
  }
}

.mobile-version {
  .content {
    padding-top: 0;
    padding-bottom: 58px;
    overflow: hidden;
  }
}

.restricted-main {
  width: 100%;
  margin-left: 220px;
  padding: 15px;
  position: relative;
  // margin-left: 220px;
}

.mobile-version {
  .restricted-main {
    margin-left: 0;
    padding: 0;
    padding-top: 14px;
  }
}

@media (max-width: 988px) {
  .restricted-main {
    width: 100%;
  }
}
