.check-list {
  display: flex;
}

.check-item {
  flex: 1;
  padding: 15px;

  &,
  > input {
    cursor: pointer;
  }

  > span {
    display: block;
  }
}

.mobile-version {
  .check-item {
    padding: 3px;
  }
}
