@import '../../../variables.scss';

.survey-question {
  .options {
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0px 8px;
      height: 40px;
      width: 40px;
      background: $text-color;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      transition: all .2s;
    }

    button:hover {
      background: var(--primary-color);
      opacity: .5;
      transition: all .2s;
    }

    button.active {
      background: var(--primary-color);
    }

    button.active:hover {
      opacity: 1;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 8px;
      border-radius: 4px;
      border: 2px solid var(--primary-color);
      padding: 8px 32px;

      i {
        margin-right: 8px;
      }
    }

    button:hover {
      i {
        color: #ffffff;
      }
    }
  }
}
