.chat-form {
  h2 {
    margin: 32px 0px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .check-wrapper {
    background: none;

    span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
    }
  }

  .submit-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
