@import '../../variables.scss';

.button {
  display: inline-block;
  padding: 6px 18px;
  line-height: 1;
  min-height: 34px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background 200ms;
  font-size: 14px;

  i {
    margin-right: 4px;
    display: inline-block;
    position: relative;
    top: 1px;
  }

  &.fill {
    width: 100%;
  }

  &.small {
    padding: 6px 15px;
    font-size: 0.8rem;
  }

  &.rounded {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
  }

  &.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    padding: 0;
    font-size: 1.5rem;

    &.small {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1rem;
    }
  }

  &.btn-copy {
    margin-right: 8px;
  }

  &.black {
    color: $dark-color;

    &:hover {
      color: lighten($dark-color, 10%);
    }
  }

  &.primary {
    background: var(--primary-color);
  }

  &.secondary {
    background: var(--secondary-color);
  }

  &.success {
    background: var(--success-color);
  }

  &.danger {
    background: $danger-color;
    &:hover {
      background: lighten($danger-color, 10%);
    }
  }

  &.dark {
    background: $dark-color;
    &:hover {
      background: lighten($dark-color, 10%);
    }
  }

  &.facebook {
    background: $facebook-color;
  }

  &.whatsapp {
    background: $whatsapp-color;
  }

  &.instagram {
    background: $instagram-color;
  }

  &.linkedin {
    background: $linkedin-color;
  }

  &.twitter {
    background: $twitter-color;
  }

  &.x {
    background: $x-color;
  }

  &.youtube {
    background: $youtube-color;
  }

  &.publishing {
    background: var(--primary-color);
  }

  &.kaltura {
    background: $kaltura-color;
  }

  &.link {
    padding: 0 5px;
    background: none;
    border: none;
    font-weight: normal;

    &:hover {
      background: none;
    }

    &.black {
      color: $dark-color;

      &:hover {
        color: lighten($dark-color, 10%);
      }
    }

    &.primary {
      color: var(--primary-color);

      &:hover {
        // color: lighten(var(--primary-color), 10%);
      }
    }

    &.secondary {
      color: var(--secondary-color);

      &:hover {
        // color: lighten(var(--secondary-color), 10%);
      }
    }

    &.danger {
      color: $danger-color;

      &:hover {
        color: lighten($danger-color, 10%);
      }
    }
  }

  &.outline {
    border: 1px solid;
    background: none;

    &.black {
      color: $dark-color;
      border-color: var(--border-color);

      &:hover {
        color: $dark-inverse;
      }
    }

    &.primary {
      color: var(--primary-color);
      border-color: var(--border-color);

      &:hover {
        color: var(--primary-color);
        background-color: var(--background-color);
      }
    }

    &.secondary {
      color: var(--primary-color);
      border-color: var(--border-color);

      &:hover {
        color: var(--primary-color);
        background-color: var(--background-color);
      }
    }

    &.success {
      color: var(--success-color);
      border-color: var(--success-color);

      &:hover {
        color: $success-inverse;
        background: var(--success-color);
      }
    }

    &.danger {
      color: $danger-color;
      border-color: $danger-color;

      &:hover {
        color: $danger-inverse;
        background: $danger-color;
      }
    }

    &.dark {
      color: $dark-color;
      border-color: $dark-color;

      &:hover {
        color: $dark-inverse;
        background: $dark-color;
      }
    }

    &.facebook {
      color: $facebook-color;
      border-color: $facebook-color;

      &:hover {
        color: $facebook-inverse;
        background: $facebook-color;
      }
    }

    &.whatsapp {
      color: $whatsapp-color;
      border-color: $whatsapp-color;

      &:hover {
        color: $whatsapp-inverse;
        background: $whatsapp-color;
      }
    }

    &.instagram {
      color: $instagram-color;
      border-color: $instagram-color;

      &:hover {
        color: $instagram-inverse;
        background: $instagram-color;
      }
    }

    &.linkedin {
      color: $linkedin-color;
      border-color: $linkedin-color;

      &:hover {
        color: $linkedin-inverse;
        background: $linkedin-color;
      }
    }

    &.twitter {
      color: $twitter-color;
      border-color: $twitter-color;

      &:hover {
        color: $twitter-inverse;
        background: $twitter-color;
      }
    }

    &.x {
      color: $x-color;
      border-color: $x-color;

      &:hover {
        color: $x-inverse;
        background: $x-color;
      }
    }

    &.youtube {
      color: $youtube-color;
      border-color: $youtube-color;

      &:hover {
        color: $youtube-inverse;
        background: $youtube-color;
      }
    }

    &.publishing {
      color: var(--primary-color);
      border-color: var(--primary-color);

      &:hover {
        color: var(--primary-inverse-color);
        background: $publishing-color;
      }
    }
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }
}

.mobile-version {
  .button {
    &, &.fill {
      padding: 5px 20px;
    }

    &.circle {
      padding: 0;
    }

    &.btn-copy {
      margin-bottom: 8px;
    }
  }
}
