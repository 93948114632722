@import '../../variables.scss';

.default-modal-overlay {
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(#000, 0.75);
  z-index: $z-index-modal;
  padding-top: 40px;

  display: flex;
  justify-content: center;
}

.default-modal {
  width: 100%;
  max-width: 598px;
  height: max-content;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-color);
  background: #ffffff;
  color: var(--text-color);
  border-radius: 4px;
}

.default-modal > div {
  width: 100%;
}

.default-modal .modal-header {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.default-modal .modal-header .close {
  width: 38px;
  height: 38px;
  font-size: 28px;
  line-height: 1;
  border: none;
  border-radius: 4px;
  background: var(--background-color);
  color: var(--secondary-text-color);
  outline: none;
  cursor: pointer;
}

.default-modal .modal-content {
  padding: 0px 64px 16px 64px;
}

@media (max-width: 720px) {
  .default-modal-overlay {
    padding: 16px;

    .modal-content {
      padding: 0px 16px 16px 16px;
    }
  }
}
