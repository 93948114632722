@import '../../variables.scss';

.new-default-modal-overlay {
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(#000, 0.75);
  z-index: $z-index-modal;
  padding-top: 30px;

  display: flex;
  justify-content: center;
}

.new-default-modal {
  width: 100%;
  max-width: 598px;
  height: max-content;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-color);
  background: var(--white-color);
  color: var(--text-color);
  border-radius: 16px;
}

.new-default-modal > div {
  width: 100%;
}

.new-default-modal .modal-header {
  width: auto;
  position: absolute;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  top: 22px;
  right: 22px;
}

.new-default-modal .modal-header .close {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  color: var(--grey80);
  background: transparent;

  font-size: 27px;
  line-height: 1;

  padding: 0;

  border: none;
  outline: none;

  cursor: pointer;

  transition: background .2s ease;
}

.new-default-modal .modal-header .close:hover {
  background: var(--grey20);
}

.new-default-modal .modal-header .close:active {
  color: var(--grey100);
}

.new-default-modal .modal-content {
  padding: 22px;
}

.new-default-modal .modal-content .title {
  font-size: 18px;
  font-weight: 700;

  color: var(--grey100);
  margin: 8px 40px 18px 0px;
}

.new-default-modal .modal-content .subtitle {
  font-weight: 450;
  font-size: 16px;
}

.new-default-modal .modal-content .modal-footer {
  margin-top: 22px;
  gap: 12px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 720px) {
  .new-default-modal-overlay {
    padding: 16px;
  }
}
