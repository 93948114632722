.task-in-validation-content {
  padding: 0 24px 16px;

  .task-in-validation-body {
    margin: 24px 0 16px;

    p {
      font-size: 16px;
    }
  }
}
