@import '../../../variables.scss';

.container {
  width: 100%;
  max-width: 950px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @at-root .restricted-wrapper & {
    max-width: 1100px;
  }
}
