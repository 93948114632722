.card {
  padding: 15px;
  margin: auto;
  position: relative;
  overflow: hidden;
  color: #4a4a4a;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--border-color);

  & + & {
    margin-top: 15px;
  }
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  > div {
    flex: 1;
  }
}

.card-footer-options {
  padding: 0 30px;
  position: absolute;
  bottom: 100%;
  left: -15px;
  right: -15px;
  color: #333;
  background: #fff;
  box-shadow: 0 -4px 8px 0 rgba(#000, 0.1);
}

.card-footer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin: 5px;
  }
}
