@import '../../variables.scss';

.contact-chat {
  .chat-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;

    a, span {
      width: 100px;
      padding: 0px;
    }
  }

  .chat-messages {
    height: 464px;
    overflow-y: scroll;
    margin-bottom: 16px;

    .message-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 32px;

      .message {
        width: max-content;
        min-width: 200px;
        max-width: 50%;
        background-color: #ffffff;
        border: 1px solid #dde0e6;
        padding: 8px;
        border-radius: 6px;
        font-weight: bold;
        margin-left: 8px;
        margin-top: 4px;
      }
    }

    .message-date {
      width: 100%;
      text-align: end;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }

    .message-container.me {
      justify-content: flex-end;

      .message {
        background-color: var(--secondary-color);
        border: none;
        box-shadow: 0 -1px 8px 0 rgba(0,0,0,.1);
        color: #fff;
        margin-right: 8px;

        .message-date {
          width: 100%;
          text-align: end;
          font-size: 12px;
          font-weight: normal;
          font-style: italic;
        }
      }
    }
  }

  .message-field {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 4px 8px;
    border-radius: 6px;

    .input {
      min-height: 40px;
      margin: 0px;
      margin-right: 8px;
    }

    button {
      padding: 8px 12px;
      width: 100px;
      font-weight: bold;
    }
  }
}
