@import '../../variables.scss';

.rumor-info {
  display: inline-block;
  padding: 10px 20px;
  position: absolute;
  left: -15px;
  bottom: 0;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-top: 21px solid transparent;
    border-left: 20px solid;
    border-bottom: 21px solid transparent;
  }

  &.true {
    color: var(--background-inverse-color);
    background: var(--primary-color);

    &:after {
      border-left-color: var(--primary-color);
    }
  }

  &.false {
    color: $danger-inverse;
    background: $danger-color;

    &:after {
      border-left-color: $danger-color;
    }
  }
}
