@import '../../variables.scss';

.mobile-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: $z-index-header;
  display: flex;
  justify-content: space-evenly;
  height: 58px;

  .trail-icon {
    width: 32px;
    height: 32px;
  }
}
