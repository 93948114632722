.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  &.center {
    align-items: center;
  }
}

@media (min-width: 900px) {
  .row.reverse {
    flex-direction: row-reverse;
  }
}
