.poll {
  margin-bottom: 15px;

  .open-date {
    font-size: 12px;
    margin-bottom: 15px;
    color: var(--secondary-text-color) !important;
    font-weight: normal !important;
  }

  .button {
    margin: 5px;
  }

  p {
    color: #ffffff !important;
    font-weight: bold !important;
  }
}
