@import '../../variables.scss';

.media-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  background: rgba(#000, 0.5);
}

.media-modal-content {
  position: relative;
  background: #fff;

  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
    color: $secondary-inverse;
    background: var(--primary-color);
    border-radius: 50%;
    opacity: 1;
  }
}
