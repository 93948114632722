@import '../../../variables.scss';

.survey-question-status {
  width: max-content;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var($text-color);

  span {
    color: var(--primary-color);
  }
}
