@import '../../variables.scss';

.library-link {
  width: 31%;
  min-width: 200px;
  margin: 0px 16px 16px 0px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;

  p {
    margin: 0;
  }

  .preview {
    width: 100%;
    height: 128px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 32px;
      color: #8f9194;
    }
  }

  .description {
    padding: 16px;

    .title {
      display: flex;
      margin-bottom: 8px;

      .text {
        max-width: 14ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a {
        color: var(--primary-color);
        max-width: 18ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .info {
      display: flex;
      justify-content: flex-end;
      color: #8f9194;
    }
  }
}
