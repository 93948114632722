@import '../../variables.scss';

.external-action-confirm, .external-action-explanation {
  padding: 10px;
  color: $dark-color;

  .continue-button {
    margin-top: 20px;
  }

  .checkbox-announce {
    padding: 10px 0;
  }
}

.external-action-confirm div + div {
  margin-top: 8px;
}

.confirm-question {
  margin: 12px 0;
  font-size: 20px;
  color: var(--primary-color);
}
