.task-prize {
  display: inline-block;
  margin: 20px 0;
  position: relative;
}

.task-prize-coin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  height: 110px;
  padding: 10px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../assets/images/stars.svg') center no-repeat;
  }

  .coin-image {
    width: 96px;
    height: 96px;
    background: url('../../assets/images/levels.png') no-repeat;

    &.bronze {
      background-position: 0 3px;
    }

    &.silver {
      background-position: 0 -96px;
    }

    &.gold {
      background-position: 0 -195px;
    }

    &.emerald {
      background-position: 0 -294px;
    }

    &.diamond {
      background-position: 0 -393px;
    }

    &.star {
      background: url('../../assets/images/star.svg') no-repeat;
    }

    &.reward {
      background: url('../../assets/images/Reward-Badge@2x.png') no-repeat;
      background-size: contain;
    }

    &.prize-star-reward {
      background: url('../../assets/images/prize-star-reward.svg') no-repeat;
      background-size: contain;
    }
  }
}

.task-prize-image {
  max-width: 32px;
  max-height: 32px;
  position: absolute;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  &.top-left {
    top: 5px;
    left: 5px;
  }

  &.bottom-right {
    bottom: 5px;
    right: 5px;
  }
}

.mobile-version {
  .task-prize {
    margin: 0;
  }
}
