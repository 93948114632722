.logo {
  &.default {
    .logo-geracao {
      fill: #868686;
    }

    .logo-sesi {
      fill: #6bb230;
    }

    .logo-senai {
      fill: #f08607;
    }

    .logo-message {
      fill: #f08607;
    }

    .logo-part-1 {
      fill: #f08607;
    }

    .logo-part-2 {
      fill: #69ae2f;
    }
  }

  &.reverse {
    .logo-geracao {
      fill: #fff;
    }

    .logo-sesi {
      fill: #fff;
    }

    .logo-senai {
      fill: #fff;
    }

    .logo-message {
      fill: #fff;
    }

    .logo-part-1 {
      fill: #f08607;
    }

    .logo-part-2 {
      fill: #fff;
    }
  }
}
