// SCSS variables

// Font
$font-size-base: 14px;
$font-family: 'CircularStd', sans-serif;

// Restrict header
$header-height: 74px;

// Restrict menu
$menu-width: 220px;

// Grid
$break-min: 850px;
$break-max: $break-min - 1px;

// Z-indexes
$z-index-badge-count: 1200;
$z-index-message: 2000;
$z-index-loading: 750;
$z-index-more-screen: 900;
$z-index-header: 1000;
$z-index-modal: 2000;

// Colors
$text-color: #4a4a4a;
$secondary-text-color: #bbbec4;
$border-color: #edf1f2;
$background-placeholder-color: #eaeaef;
$background-color: #f6f6fb;
$white-color: #ffffff;
$background-inverse-color: #8f9194;
$header-height: 76px;
$primary-color: #4cb392;
$secondary-color: #4cb392;
$secondary-nav-color: #77a1ff;
$tertiary-color: #06635c;
$primary-inverse-color: #ffffff;
$secondary-inverse-color: #4a4a4a;
$success-color: #4cb392;
$failure-color: #f05050;
$warning-color: #f4b21b;
$success-inverse-color: #ffffff;
$white-color: #fff;
$gray: #f6f7fb;
$dark-color: #111;
$dark-inverse: $white-color;
$secondary-inverse: $white-color;
$danger-color: #f00;
$danger-inverse: $white-color;
$success-inverse: $white-color;
$light-color: $white-color;
$light-inverse: #333;
$link-color: $secondary-color;
$text-light-color: #8f9194;
$facebook-color: #3a589b;
$facebook-inverse: $white-color;
$whatsapp-color: #09d261;
$whatsapp-inverse: $white-color;
$instagram-color: radial-gradient(
  circle at 1.83% 90.83%,
  #fed576 0,
  #f47133 26%,
  #bc3081 61%,
  #4c63d2 100%
);
$instagram-inverse: $white-color;
$linkedin-color: #0084bf;
$linkedin-inverse: $white-color;
$twitter-color: #5189cb;
$twitter-inverse: $white-color;
$x-color: #040404;
$x-inverse: $white-color;
$youtube-color: #cc181e;
$youtube-inverse: $white-color;
$publishing-color: $secondary-color;
$publishing-inverse: $secondary-inverse;
$kaltura-color: #1b4a97;
$input-border-color: #edeff4;
$badge-color: #ff8f08;
$badge-background: $white-color;
$restrict-header-color: $text-color;
$restrict-header-background: #fff;
$separator-color: #dde0e6;
$login-bg: #f6f7fb;
$more-bg: #f6f7fb;
$restrict-header-color: $text-color;

$brand-primary-color: #00BC9B;
$brand-primary-color-hover: rgba(0, 188, 155, 0.1);
$brand-tertiary-color: #5D59D2;

$alert-secondary-color: #F4B21B;
$alert-tertiary-color-hover: rgba(255, 75, 42, 0.1);
$alert-tertiary-color: #FF4B2A;

$grey100: #4A4A4A;
$grey80: #999999;
$grey60: #BBBEC4;
$grey40: #DDE0E6;
$grey20: #F6F7FB;

$purple-color: #262B53;

:root {
  --text-color: #{$text-color};
  --secondary-text-color: #{$secondary-text-color};
  --border-color: #{$border-color};
  --background-placeholder-color: #{$background-placeholder-color};
  --background-color: #{$background-color};
  --white-color: #{$white-color};
  --background-inverse-color: #{$background-inverse-color};
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --secondary-nav-color: #{$secondary-nav-color};
  --tertiary-color: #{$tertiary-color};
  --primary-inverse-color: #{$primary-inverse-color};
  --secondary-inverse-color: #{$secondary-inverse-color};
  --success-color: #{$success-color};
  --failure-color: #{$failure-color};
  --warning-color: #{$warning-color};
  --success-inverse-color: #{$success-inverse-color};

  --header-height: 76px;

  /* Paleta de cores seguindo o System Design */

  /* Brand */
  --brand-primary-color: #{$brand-primary-color};
  --brand-primary-color-hover: #{$brand-primary-color-hover};
  --brand-tertiary-color: #{$brand-tertiary-color};

  /* Alert */
  --alert-secondary-color: #{$alert-secondary-color};
  --alert-tertiary-color: #{$alert-tertiary-color};
  --alert-tertiary-color-hover: #{$alert-tertiary-color-hover};

  /* Monochromatic */
  --grey100: #{$grey100};
  --grey80: #{$grey80};
  --grey60: #{$grey60};
  --grey40: #{$grey40};
  --grey20: #{$grey20};

  /* Greyscale */
  --purple-color: #{$purple-color};
}
