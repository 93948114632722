@import '../../variables.scss';

.search-aside {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .copyright {
    position: fixed;
    z-index: 1;
    top: 194px;
    max-width: 226px;
    margin: 0px;
    -webkit-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .stores-links {
    position: fixed;
    z-index: 1;
    top: 240px;
    max-width: 226px;
    margin: 0px;
    -webkit-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border-top: 1px solid #0002;
    font-size: 12.5px;
    padding-top: 16px;

    p {
      margin: 0px;
    }
  }
}

.search-area {
  position: fixed;
  top: var(--header-height);
  width: 100%;
  max-width: 226px;
  padding: 15px;
  border-radius: 0 0 4px 4px;
  color: $text-color;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 2;
}

.mobile-search-wrapper {
  display: flex;

  margin-bottom: 15px;

  gap: 14px;

  .search-area {
    // width: auto;

    .search-input {
      margin: 0;
    }

    button {
      margin-bottom: 0;
    }

    span {
      display: none;
    }
  }

  .notification-btn {
    background: transparent;

    border: none;
    outline: none;

    padding: 0;

    margin-right: 14px;
    line-height: 0.6;

    cursor: pointer;
  }

  .notification-btn img {
    width: 34px;
  }
}

@media (max-width: 988px) {
  .search-area {
    position: relative;
    top: 0px;
    width: 100%;
    max-width: 100%;
  }
}
