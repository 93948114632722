.collapse-post {
  margin: 0 -15px -15px;
  text-align: center;
  background: #f6f6f6;
}

.collapse-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  cursor: pointer;
}

.collapse-info {
  flex: 1;
  text-align: left;

  p {
    font-size: 12px;
    color: var(--secondary-text-color);
  }

  > h3 {
    margin: 0;
  }
}

.collapse-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 0.8rem;
  border: #999 1px solid;
  border-radius: 50%;
}

.collapse-content {
  display: none;
  padding: 15px;
}

.collapse-content-wrapper {
  max-width: 375px;
  margin: auto;
}

.collapse-post.visible,
.collapse-post.expanded {
  .collapse-content {
    display: block;
  }
}

.collapse-post.expanded {
  .collapse-header {
    cursor: default;
  }
}
