@import '../../variables.scss';

.message-modal {
  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    height: 30px;
    width: 30px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 50%;
  }
}
