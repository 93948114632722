@import '../../variables.scss';

.network-row {
  font-family: CircularStd;
  font-size: 18px;
  font-weight: 500;
  color: $text-color;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;

  .button {
    margin: 0;
    width: 200px;
    padding-left: 0;
    padding-right: 0;
  }

  .rounded-icon {
    margin-right: 15px;
  }

  .mobile-version & {
    flex-direction: column;
    align-items: initial;

    > div {
      margin-bottom: 10px;
    }

    .button {
      width: 100%;
    }
  }
}

.rounded-icon {
  width: 48px;
  height: 48px;
  display: inline-block;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;

  &.facebook {
    background: $facebook-color;
  }

  &.whatsapp {
    background: $whatsapp-color;
  }

  &.instagram {
    background: $instagram-color;
  }

  &.linkedin {
    background: $linkedin-color;
  }

  &.twitter {
    background: $twitter-color;
  }

  &.youtube {
    background: $youtube-color;
  }

  &.publishing {
    background: $publishing-color;
  }
}
