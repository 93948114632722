@import '../../variables.scss';

.nav-menu {
  width: $menu-width;
  border-radius: 0 0 4px 4px;
  color: $text-color;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.nav {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    display: block;

    + li {
      border-top: #f6f7fb 1px solid;
    }
  }
}

.nav-header {
  padding: 15px;
  font-weight: 500;
  background: var(--primary-color);
  color: #ffffff;

  img {
    max-width: 32px;
    max-height: 32px;
    margin-right: 10px;
  }
}
