.cursor-pointer {
  cursor: pointer;
}

.post-comment {
  span {
    margin-left: 5px;
  }

  &.inline-block {
    display: inline-block
  }
}
