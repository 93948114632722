@import '../../variables.scss';

.w300 {
  width: 300px;
}

.flex {
  > div {
    flex: 1;

    &.no-flex {
      flex: initial;
    }
  }
}

.mobile-version {
  .ranking-organization {
    padding: 10px;
    padding-top: 20px;

    > .flex {
      display: block;

      .w300 {
        width: auto;
      }
    }

    .org-image {
      margin-top: 0;
    }

    .badges-list {
      margin-top: 30px;
    }
  }

  .ranking {
    margin-top: 20px;
  }
}


.ranking-organization {
  padding: 30px;
  border-bottom: 1px solid $gray;

  .org-image {
    margin-top: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .ranking-organization-title {
    font-size: 18px;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $text-color;
    margin-top: 15px;
  }

  .org-points {
    font-size: 24px;
    font-weight: var(--bold);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-color);
    margin-top: 5px;
  }

  .badges-list {
    margin-top: 52px;

    .badge-item, .more-badges {
      margin-left: -18px;
      width: 48px;
      height: 48px;
      display: inline-block;
      vertical-align: top;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .more-badges {
      display: inline-block;
      background-color: var(--primary-color);
      color: white;
      text-align: center;
      line-height: 48px;
    }
  }

  .badges-list-title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: center;
    color: $text-color;
    display: block;
    margin-bottom: 16px;
  }
}


.ranking {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0px;
  }

  .ranking-header {
    padding: 12px;
    color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background-color: var(--primary-color);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-items: center;

    h2 {
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      font-weight: var(--normal)
    }

    time  {
      font-weight: var(--thin);
      font-size: 14px;
      font-weight: var(--normal);
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
    }
  }

  .groups-name {
    margin: 12px;
  }

  .position-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 25px;
  }

  .position {
    display: flex;
    margin-bottom: 17px;
    align-items: center;

    &.first-account {
      .badge-count {
        background-color: #f4b21b;
      }
    }

    &.my-position {
      .badge-count {
        background-color: var(--primary-color);
      }
    }

    &.with-dot {
      margin-bottom: 33px;
    }

    .pos-name {
      position: relative;
      z-index: 0;
      display: flex;
      align-items: center;
      flex: 1;

      .position-dots {
        font-size: 18px;
        font-weight: var(--bold);
        font-style: normal;
        font-stretch: normal;
        line-height: 8px;
        letter-spacing: normal;
        text-align: center;
        color: $text-color;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 100%;
        margin-left: -2px;
      }
    }

    .badge-count {
      width: 32px;
      height: 32px;
      background-color: $text-color;
      color: white;
      text-align: center;
      position: relative;
      z-index: $z-index-badge-count;

      display: flex;
      align-items: center;
      justify-content: center;

      &.size-position-3 {
        font-size: 12px;
      }

      &.size-position-4 {
        font-size: 9px;
      }

      &.size-position-5 {
        font-size: 9px;
        padding: 6px;
      }

      &.size-position-6,
      &.size-position-7 {
        font-size: 8px;
        padding: 6px;
      }
    }

    .position-avatar-wrapper {
      width: 48px;
      height: 48px;
      margin-left: -10px;
      margin-right: 10px;
      position: relative;
    }

    .position-avatar {
      width: 48px;
      height: 48px;
    }

    .position-points {
      font-size: 16px;
      font-weight: var(--bold);
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: $text-color;
    }

    .position-title {
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $text-color;
    }
  }

  .ranking-footer {
    .button {
      line-height: 1.5;
    }
  }
}

.title-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img48 {
  width: 48px;
  height: 48px;
}

.ml10 {
  margin-left: 10px;
}

.you-at-ranking-wrapper {
  display: flex;
  align-items: center;
}

.you-at-ranking-box {
  .values {
    font-size: 18px;
    font-weight: var(--bold);
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ff8f08;

    .sep {
      color: #dde0e6;
    }
  }
}

.you-at-ranking {
  font-size: 10px;
  font-weight: var(--normal);
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #8f9194;
  text-transform: uppercase;
  display: block;
  text-align: right;
  margin-bottom: 7px;
}

.mobile-version {
  .ranking-table-header {
    .h-position {
      width: 65px;
    }

    .h-points {
      width: auto;
    }
  }

  .ranking.ranking-tabled .position {
    .position-avatar-wrapper {
      margin-left: 0;
    }

    .position-points {
      width: auto;
    }
  }

  .ranking {
    .position {
      .pos-name {
        margin-right: 10px;
      }
    }
  }

}

.ranking.ranking-tabled {
  margin-bottom: 0;

  .position {
    border-bottom: 1px solid #f6f7fb;
    padding-bottom: 17px;

    &.my-position {
      .badge-count, .position-title, .position-points {
        color: var(--primary-color);
      }
    }

    .position-avatar-wrapper {
      margin-left: 80px;
    }

    .position-avatar, .position-avatar-wrapper {
      width: 32px;
      height: 32px;
    }

    .badge-count {
      background: none;
      color: $text-color;
    }

    .position-points {
      width: 170px;
      text-align: left;
      display: block;
    }
  }
}

.ranking-table-header {
  background-color: var(--primary-color);
  color: white;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .h-position {
    width: 100px;
  }

  .h-points {
    width: 170px;
  }

  .h-name {
    flex: 1;
  }
}

.ranking-btn.button {
  min-height: initial;

  padding: 0;

  font-size: 16px;
  text-decoration: none;

  white-space: nowrap;

  & i {
    margin-right: 7px;
  }
}

.ranking-btn.button:hover,
.ranking-btn.button:focus:hover {
  color: var(--brand-tertiary-color);
}

.back-to-list.button {
  padding-top: 3px;
}

.back-to-list.button + .ranking-list-header {
  margin-top: 42px;
}

.ranking-list-header + .ranking-reward,
.ranking-reward + .spark-table,
.ranking-list-header + .spark-table {
  margin-top: 38px;
}

.ranking-list-header,
.ranking-reward,
.spark-table,
.back-to-list.button {
  padding-left: 33px;
}

.spark-table {
  margin-bottom: 18px;
}

@media (max-width: 987px) {
  .ranking-list-header,
  .ranking-reward,
  .spark-table,
  .back-to-list.button {
    padding-left: 0px;
  }
}
