@import '../../variables.scss';

.toggle-org {
  padding: 0px;

  button {
    cursor: pointer;
    padding: 15px;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        border-radius: 50%;
      }
    }
  }

  .org-options {
    overflow: hidden;
    max-height: 0px;
    transition: all .5s;

    button {
      border-bottom: 2px solid #00000010;
    }

    button:first-child {
      border-top: 2px solid #00000010;
    }

    button:hover {
      background-color: #00000010;
    }
  }

  .org-options.opened {
    overflow-y: scroll;
    max-height: 220px;
  }

  .org-options.opened::-webkit-scrollbar-track {
    background-color: var(--primary-color);
  }
  .org-options.opened::-webkit-scrollbar {
    width: 6px;
    background: var(--primary-color);
  }
  .org-options.opened::-webkit-scrollbar-thumb {
    background: #00000030;
  }
}

.more-screen .toggle-org {
  background-color: transparent;
  margin-bottom: 32px;

  .active-org {
    display: flex;
  }
}
