.links-dropdown {
  position: relative;
  margin: 0px 4px;

  .toggle-dropdown {
    background: #ffffff;
    width: 32px;
    height: 32px;
    font-size: 20px;
    line-height: 1;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #8f9194;

    &:hover {
      background: #f0f0f0;
    }
  }

  .dropdown-content {
    width: 200px;
    max-height: 0px;
    position: absolute;
    left: 0px;
    top: 36px;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    z-index: 1000;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;

    button {
      padding: 4px 8px;
      text-align: start;
      color: var(--primary-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown-content.open {
    max-height: max-content;
    border: 1px solid #eee;
  }
}
