.creators-form {
  width: 100%;
  & > .title {
    color: var(--text-color);
    font-size: 20px;
  }

  .question-list {
    width: 100%;
  }

  .actions {
    margin: 16px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .custom-loading {
    margin: 32px 0px;
  }
}

.action-finish-alert {
  padding: 16px;
  text-align: center;
}
