.breadcrumb {
  padding: 16px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8f9194;
  }
}

.breadcrumb .link-item {
  display: flex;
  align-items: center;
}

.breadcrumb .link-item button {
  padding: 8px 6px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f9194;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

.breadcrumb .link-item p {
  color: #8f9194;
}

.breadcrumb .link-item.active button {
  color: var(--primary-color);
}
