.col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

@media (min-width: 900px) {
  .col.col-12 {
    flex: 1;
  }

  .col.col-11 {
    flex: 0 0 91.666%;
  }

  .col.col-10 {
    flex: 0 0 83.333%;
  }

  .col.col-9 {
    flex: 0 0 75%;
  }

  .col.col-8 {
    flex: 0 0 66.666%;
  }

  .col.col-7 {
    flex: 0 0 58.333%;
  }

  .col.col-6 {
    flex: 0 0 50%;
  }

  .col.col-5 {
    flex: 0 0 41.666%;
  }

  .col.col-4 {
    flex: 0 0 33.333%;
  }

  .col.col-3 {
    flex: 0 0 25%;
  }

  .col.col-2 {
    flex: 0 0 16.666%;
  }

  .col.col-1 {
    flex: 0 0 8.333%;
  }
}
