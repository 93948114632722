.link-preview-media {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }

  img {
    width: 100%;
  }
}
