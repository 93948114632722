@import '../../../variables.scss';

.survey-card-header {
  padding-bottom: 16px;
  border-bottom: 1px solid #eef0f5;

  .title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $text-color;
  }

  .description {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f9194;
  }
}

.survey-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .statistics {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 32px;

    .statistic-item {
      margin: 16px 32px;
      text-align: center;

      .value {
        margin: 0px;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--primary-color);

        span {
          font-size: 18px;
        }
      }

      .label {
        margin: 0px;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  .action {
    padding: 32px;
    padding-top: 20px;

    .button {
      padding: 12px 74px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }

    i {
      margin-right: 8px;
    }
  }
}
