@import '../../variables.scss';

.task-modal-row-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: var(--normal);
  color: #4a4a4a;
  align-items: center;
  margin-bottom: 7px;

  p {
    margin: 0;
  }

  .muted {
    color: #bbbec4;
  }

  > div:last-child {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: var(--bold);
  }

  &.lg {
    font-size: 18px;
    font-weight: var(--bold);
    line-height: 2em;

    > div:last-child {
      font-size: 36px;
    }

  }

}

.questionnaire-info {
  display: flex;
  flex-direction: column;
}

.task-complete-modal {
  border-radius: 8px;
  overflow: hidden;

  .prize {
    text-align: center;

    .reward-icon {
      margin-right: 0;
      width: 40px;
    }
  }

  .prize-title {
    color: var(--primary-color);
  }

  .point-message {
    text-align: center;
    font-size: 18px;
    color: var(--primary-color);
    margin-bottom: 40px;
  }
}

.task-modal-separator {
  border: none;
  border-top: 2px dashed #dde0e6;
  margin: 15px 0;

  .mobile-version & {
    margin: 10px 0;
  }
}

.unlocked-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
    line-height: 16px;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: var(--primary-color);
      color: #fff;
      border-radius: 50%;
      padding: 4px;
    }
  }

  button {
    display: inline-block;
    width: 260px;
    height: 28px;
    padding: 4px 0px;
    margin: 0;
    line-height: 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: var(--primary-color);
    background: none;
    border: 1px solid var(--primary-color);
    border-radius: 100px;
    -webkit-transition: background 200ms;
    transition: background 200ms
  }
}
