@import '../../variables.scss';

.like-button, .unlike-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  margin-right: -2px;
  margin-left: 5px;

  &:first-child {
    margin-left: -5px;
  }

  &:hover {
    opacity: 0.85;
  }

  &.no-actions {
    cursor: initial;
  }
}

.like-button {
  &.executed {
    color: var(--success-color);
  }
}

.unlike-button {
  &.executed {
    color: $danger-color;
  }
}
